import { render, staticRenderFns } from "./HomeSlider.vue?vue&type=template&id=78ec529f&scoped=true"
import script from "./HomeSlider.vue?vue&type=script&lang=js"
export * from "./HomeSlider.vue?vue&type=script&lang=js"
import style0 from "./HomeSlider.vue?vue&type=style&index=0&id=78ec529f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78ec529f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonsNuxtImage: require('/app/components/Commons/NuxtImage.vue').default})
